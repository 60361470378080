<template>
  <div class="auth-layout" data-app> 
    <div class="auth-grid">
      <main class="auth-content">
        <nuxt-link :to="{ name: 'index' }" :disabled="zap === undefined">
          <img class="auth-logo" src="/assets/img/auth/clickzap_logo.png" alt="clickzap logo">
        </nuxt-link>
        <nuxt />
      </main>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from '@/components/base/footer'

export default {
  components: { Footer },
  methods: {}
}
</script>

<style lang="scss" scoped>
@import 'assets/scss/variables';

.auth-layout {
  background-image: url('/assets/img/auth/background.png');
  background-repeat: repeat-x;
  height: 100vh;
  display: grid;
  grid-template-rows: 1fr 0fr;
}

.auth-grid {
  display: grid;
  grid-template-rows: 1fr;
  overflow: auto;
}

.auth-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  margin: 0 auto;
  padding: $scale600;
  width: 100%;
  max-width: 452px;

  @include media-query($small-breakpoint) {
    padding: $scale800;
    max-width: 468px;
  }
}

.auth-logo {
  margin-bottom: $scale900;
}
</style>
