<template>
  <div data-app>
    <cz-header-notification />
    <cz-header />
    <div
      v-if="isFree || countDown == 'EXPIROU' || loggedUserInactive || (isPix && invoiceUrl)"
      :style="bannerColor"
    >
      <button
        v-if="countDown"
        class="white--text"
        style="width: 100%; padding: 10px; line-height: 25px"
        @click="goToPlans"
      >
        <span v-if="(countDown == 'EXPIROU' || loggedUserInactive)">
          A sua conta está inativa. Para reativá-la,
          <strong>Clique Aqui.</strong>
        </span>
        <span v-else-if="isPix && invoiceUrl">
          A sua conta expira em {{ countDown }}.
          <strong>Clique Aqui</strong> e atualize a sua assinatura
        </span>
        <span v-else-if="isFree">
          O seu teste grátis termina em {{ countDown }},
          <strong>assine agora.</strong>
        </span>
      </button>
    </div>
    <div class="container container-page mt-4">
      <nuxt />
    </div>

    <whatsapp-support />

    <div class="page-footer">
      <label>© {{ currentYear }} · Clickzap. Todos os direitos reservados.</label>
    </div>
    <!-- <cz-expired ref="expired" /> -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import { redirectUserToPayment } from '@/helpers/redirects'

import CzHeader from '@/components/base/header'
import CzHeaderNotification from '@/components/base/header-notification'
import WhatsappSupport from '@/components/whatsapp-support'
import Mixpanel from '@/mixins/mixpanel'
import Sentry from '@/mixins/sentry'

export default {
  components: {
    CzHeader,
    CzHeaderNotification,
    WhatsappSupport
  },
  mixins: [Mixpanel, Sentry],
  data() {
    return {
      menu: false,
      countDown: null
    }
  },
  computed: {
    ...mapGetters([
      'isAuthenticated',
      'loggedInUser',
      'loggedUser',
      'loggedUserExpirated',
      'loggedUserInactive'
    ]),
    loggedInUserName() {
      return this.loggedInUser?.name
    },
    isPix() {
      return this.loggedInUser?.payment_method === 'pix'
    },
    invoiceUrl() {
      return this.loggedInUser?.pending_invoice_url
    },
    currentYear() {
      return new Date().getFullYear() || ''
    },
    zap() {
      return this.$store.state.zaps.zap || {}
    },
    isFree() {
      return this.loggedUser?.is_free
    },
    bannerColor() {
      if (this.countDown == 'EXPIROU' || this.loggedUserInactive) {
        return 'background-color: rgb(244, 67, 54)'
      }
      return 'background-color: rgb(242, 137, 9)'
    }
  },
  watch: {
    $route(to, from) {
      if (
        window.localStorage.getItem('auth.strategy') == 'chat' &&
        to.name != 'livechat'
      ) {
        this.$router.push({ name: 'livechat' })
      }
      if (this.loggedUserExpirated || this.loggedUserInactive) {
        // this.$refs.expired.open()
        this.goToPlans()
      }
    }
  },
  notifications: {
    showPusherMessage: {
      title: '',
      message: 'Failed to authenticate',
      type: 'success'
    }
  },
  created() {
    if (this.$route?.path === '/login/chat') {
      this.$router.push('/atendente')
    } else {
      const that = this
      if (this.loggedInUser && this.loggedInUser?.name !== '') {
        if (window.ga !== undefined) {
          window.ga('set', 'userId', this.loggedInUser?.id)
        }
      }
    }
  },
  beforeCreate() {
    if (window.localStorage.getItem('auth.strategy') == 'chat') {
      this.$router.push({ name: 'livechat' })
    }
  },
  beforeMount() {
    if (window.localStorage.getItem('auth.strategy') == 'chat') {
      this.$router.push({ name: 'livechat' })
    }
  },
  mounted() {
    this.initcountDown()
  },
  methods: {
    ...mapActions({
      getZap: 'zaps/get'
    }),
    goToPlans() {
      redirectUserToPayment(this.loggedInUser, this.$mixpanel)
    },
    initcountDown() {
      var countDownDate = new Date(this.loggedUser.expiration_date).getTime()
      var self = this
      var x = setInterval(() => {
        var now = new Date().getTime()
        var distance = countDownDate - now
        var days = Math.floor(distance / (1000 * 60 * 60 * 24))
        var hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        )
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
        var seconds = Math.floor((distance % (1000 * 60)) / 1000)
        hours = hours < 10 ? '0' + hours : hours
        minutes = minutes < 10 ? '0' + minutes : minutes
        seconds = seconds < 10 ? '0' + seconds : seconds
        self.countDown =
          days + ' dias ' + hours + 'h ' + minutes + 'min ' + seconds + 's'
        if (distance < 0) {
          self.countDown = 'EXPIROU'
          clearInterval(x)
        }
      }, 1000)
    },
    toggleMenu() {
      this.menu = this.menu ? false : true
    },
    handleCloseMenu() {
      this.menu = false
    },
    async logout() {
      this.$mixpanel.reset()
      await this.$auth.logout()
      let route_logout =
        window.localStorage.getItem('auth.strategy') == 'chat'
          ? '/login/chat'
          : '/auth/signin'
      window.location = route_logout
      localStorage.clear()
    },
    async registerClick() {
      this.$mixpanel.track('Clicked zoho support link')
    }
  }
}
</script>

<style scoped>
.page-footer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  padding: 20px;
  font-size: 14px;
  color: #6c757d;
}
.expiration {
  padding-top: 20px;
  color: red;
  text-align: center;
  font-size: 16px;
}
.mentoring-button {
  margin-top: -8px;
  border-radius: 5px 5px 5px 5px;
  height: 40px;
  float: right;
  padding: 10px 30px;
  background: white;
  color: #007aff;
  font-size: 14px;
  border: none;
  font-family: montserrat-regular;
}
</style>
