<template>
  <div class="navbar">
    <div class="container pa-0">
      <div class="nav-content-wrapper">
        <div class="navbar-content">
          <nuxt-link :to="{ name: 'index' }" :disabled="zap === undefined">
            <h5 class="navbar-logo-wrapper">
              <img
                class="navbar-logo"
                src="/assets/img/clickzap_logo.svg"
                alt="clickzap logo"
              >
            </h5>
          </nuxt-link>

          <ul v-if="showNavigation" class="nav-list desktop-only">
            <li
              v-for="link in links"
              :key="link.name"
              class="nav-item"
              :class="{ active: $route.name === link.name }"
            >
              <nuxt-link :to="{ name: link.name }">
                {{ link.label }}
              </nuxt-link>
            </li>
          </ul>
        </div>
        <span v-if="showNavigation" class="menu-wrapper">
          <span class="username">{{ username }}</span>
          <a class="menu-btn" href="javascript:void(0)" @click="toggleMenu">
            <i class="fas fa-bars" />
          </a>
        </span>
      </div>
    </div>
    <v-navigation-drawer
      v-if="showNavigation && menu"
      v-model="menu"
      absolute
      right
    >
      <template v-if="zap !== undefined">
        <div class="close-btn-wrapper">
          <button class="close-btn" @click="toggleMenu">
            <i class="fa-sharp fa-solid fa-xmark" />
          </button>
        </div>
        <ul class="nav-drawer-list">
          <li
            v-for="link in links"  
            :key="link.name"
            class="nav-drawer-item mobile-only"
          >
            <nuxt-link :to="{ name: link.name }" @click.native="toggleMenu">
              {{ link.label }}
            </nuxt-link>
          </li>
          <li class="nav-drawer-item">
            <nuxt-link :to="{ name : 'index-myaccount' }" @click.native="toggleMenu">
              Minha Conta
            </nuxt-link> 
          </li>
          <li class="nav-drawer-item">
            <a href="javascript:void(0)" @click.prevent="logout">
              Sair
            </a>
          </li>
        </ul>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

const links = [
  { label: 'Dashboard', name: 'index' },
  { label: 'Fluxos', name: 'index-sequencia' },
  { label: 'Transmissão', name: 'index-broadcast' },
  { label: 'Contatos', name: 'index-leads' },
  { label: 'Live Chat', name: 'livechat-uuid' },
  { label: 'Configurações', name: 'index-configuracao' }
]

export default {
  data() {
    return {
      menu: false,
      links
    }
  },
  computed: {
    ...mapGetters(['loggedInUser']),
    username() {
      return this.loggedInUser ? this.loggedInUser.name.split(' ')[0] : '-'
    },
    zap() {
      return this.$store.state.zaps.zap || {}
    },
    auth() {
      return localStorage && localStorage.getItem('auth.strategy')
    },
    showNavigation() {
      return (
        !['payment', 'qrcode-token', 'subscription-change'].includes(
          this.$route.name
        ) &&
        !(
          this.$route.name === 'broadcast-new' &&
          this.$route.query?.source === 'onboarding'
        ) &&
        !this.loggedInUser?.attendant_id
      )
    }
  },
  methods: {
    toggleMenu() {
      this.menu = this.menu ? false : true
    },
    handleCloseMenu() {
      this.menu = false
    },
    async logout() {
      this.$mixpanel.reset()
      await this.$auth.logout()
      let route_logout =
        window.localStorage.getItem('auth.strategy') == 'chat'
          ? '/login/chat'
          : '/auth/signin'
      window.location = route_logout
      localStorage.clear()
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'assets/scss/variables';

.mobile-only {
  display: inherit;
  @include media-query($small-breakpoint) {
    display: none;
  }
}

.desktop-only {
  display: none !important;
  @include media-query($small-breakpoint) {
    display: inherit !important;
  }
}

.menu-wrapper {
  display: flex;
  align-items: center;
}

.username {
  @include LabelSmall;
  float: none;
  margin-top: 0;
  margin-right: $scale200;
}

.menu-btn {
  color: $accent400;
}

.close-btn-wrapper {
  position: absolute;
  right: 0;
  margin-top: 10px;
  margin-right: 10px;
  z-index: 10;

  .close-btn {
    height: 34px;
    width: 34px;
    color: $accent400;
  }
}

.navbar {
  height: 100%;
  padding: 11px 20px;
  border-bottom: none;
  background-color: $mono100;
}
.container {
  font-size: 0;
}
.nuxt-link-active {
  line-height: 0;
}
.navbar-logo-wrapper {
  font-size: 0;
}
.navbar-logo {
  height: 19px;
  width: 100px;
}

.nav-content-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar-content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.nav-list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  grid-row-gap: $scale200;
  grid-column-gap: 21px;
  padding: 0;
  margin: 0 54px;
}

.nav-item {
  @include LabelSmall;
  text-transform: uppercase;
  color: $mono700;

  display: inline;

  &.active {
    &::after {
      content: ' ';
      display: block;
      padding: 5px;
      margin: -5px;
      border-bottom: 2px solid #6195ed;
    }
  }
}

.nav-drawer-list {
  padding-left: 0;
  margin-top: 10px;

  .nav-drawer-item {
    @include ParagraphSmall;
    position: relative;
    padding: 7px 0;
    margin: 0 14px;

    &:not(:last-child)::before {
      content: ' ';
      position: absolute;
      bottom: 0;
      width: 100%;
      border-bottom: 1px solid $mono400;
    }
  }
}

@include media-query($small-breakpoint) {
  .navbar {
    padding: 19px 16px;
  }
  .container {
    font-size: initial;
  }
  .navbar-logo-wrapper {
    font-size: initial;
  }
  .navbar-logo {
    height: 25px;
    width: 129px;
  }
}
</style>
