import Swal from '@/helpers/customSwal.js';
import { turndown } from '@/helpers/formatter.js'
import axios from 'axios';

export const initial_state = () => ({
  loading: false,
  sync: {
    loaded: false,
    progress: {
      recent: 0,
      full: 0
    },
    contacts: {
      error: undefined,
      loading: false,
      count: 0
    }
  },
  broadcast: {
    error: undefined,
    loading: false
  },
  campaign: {
    id: undefined,
    name: undefined, // Limited to 255 characters
    description: undefined, // Unlimited
    shortenLink: true,
    sendExitButton: false,
    media: {
      // audio: undefined, // Url to audio on CDN service
      image: undefined, // Url to image on CDN service
    },
    imageUrl: undefined,
    withImage: false,
    limit: {
      date: undefined,
      time: undefined,
      immediate: true
    }, // Timestamp Date
    contacts: { // Whatsapp contacts list
      params: undefined,
      selected: [],
      all: true
    },
    loading: false,
    error: undefined,
  }
})

export const state = initial_state;

export const getters = {
  progress(state) {
    const { progress } = state.sync

    return Math.ceil(
      (progress.recent ?? 100) / 2 + (progress.full ?? 100) / 2
    )
  }
}

export const mutations = {
  // Global mutations
  reset(state) {
    const initialState = initial_state();
    state.loading = initialState.loading;
    state.sync = initialState.sync;
    state.broadcast = initialState.broadcast;
    state.campaign = initialState.campaign;
  },

  // Loading mutations
  setLoading(state, loading) {
    state.loading = loading
  },

  // Sync mutations
  setSyncProgressRecent(state, progress) {
    if (progress === null) {
      state.sync.progress.recent = 100
    }
    if (progress > state.sync.progress.recent)
      state.sync.progress.recent = progress
  },
  setSyncProgressFull(state, progress) {
    if (progress > state.sync.progress.full)
      state.sync.progress.full = progress
  },
  setSyncContactsCount(state, count) {
    state.sync.contacts.count = count
  },
  setSyncPhoneLoaded(state, loaded) {
    state.sync.loaded = loaded
  },
  syncContactsLoading(state) {
    state.sync.contacts.loading = true
    state.sync.contacts.error = undefined
  },
  syncContactsSuccess(state) {
    state.sync.contacts.loading = false
  },
  syncContactsFailure(state, error) {
    state.sync.contacts.error = error
    state.sync.contacts.loading = false
  },

  // Campaign mutations
  setName(state, name) {
    state.campaign.name = name
  },
  setDescription(state, description) {
    state.campaign.description = description
  },
  setShortenLink(state, shorten) {
    state.campaign.shortenLink = shorten
  },
  setLimit(state, limit) {
    state.campaign.limit = limit
  },
  setImage(state, image) {
    state.campaign.media.image = image
  },
  setImageUrl(state, url) {
    state.campaign.imageUrl = url
  },
  setWithImage(state, withImage) {
    state.campaign.withImage = Boolean(withImage)
  },
  setSendExitButton(state, exit) {
    state.campaign.sendExitButton = exit
  },
  setLimitDate(state, date) {
    state.campaign.limit.date = date
  },
  setLimitTime(state, time) {
    state.campaign.limit.time = time
  },
  setLimitImmediate(state, immediate) {
    if (typeof immediate === 'string') {
      state.campaign.limit.immediate = immediate === 'true'
    } else {
      state.campaign.limit.immediate = Boolean(immediate)
    }

  },
  setContactSelectedList(state, selected) {
    state.campaign.contacts.selected = selected
  },
  setContactAllSelected(state, all) {
    state.campaign.contacts.all = all
  },
  setContactParams(state, params) {
    state.campaign.contacts.params = params
  },

  // Campaign mutations
  setCampaignLoading(state) {
    state.campaign.error = undefined
    state.campaign.loading = true
  },
  setCampaignSuccess(state, { id }) {
    state.campaign.id = id
    state.campaign.loading = false
  },
  setCampaignFailure(state, error) {
    state.campaign.error = error
    state.campaign.loading = false
  },

  // Broadcast mutations
  setBroadcastLoading(state) {
    state.broadcast.error = undefined
    state.broadcast.loading = true
  },
  setBroadcastSuccess(state, { id, title, text, file_url, send_at, use_click_tracking }) {
    state.campaign.id = id
    state.campaign.name = title
    state.campaign.description = text
    state.campaign.imageUrl = file_url
    state.campaign.withImage = Boolean(file_url)
    state.campaign.shortenLink = !!use_click_tracking
    if (send_at) {
      const [limitDate, restDate] = send_at.split('T')
      const limitTime = restDate.substring(0, 5)
      state.campaign.limit.date = limitDate 
      state.campaign.limit.time = limitTime
    }
    state.campaign.limit.immediate = !Boolean(send_at)

    state.broadcast.loading = false
  },
  setBroadcastFailure(state, error) {
    state.broadcast.error = error
    state.broadcast.loading = false
  },
}

const baseUrls = {
  // Broadcast resources
  getBroadcast: (id) => `/broadcasts/${id}`,
  searchBroadcast: () => `/broadcasts`,
  createBroadcast: () => `https://api.clickzap.io/api/v2/broadcasts`,
  updateBroadcast: (id) => `https://api.clickzap.io/api/v2/broadcasts/${id}`,
  getSignedFileUploadUrl: () => `/broadcasts/file`,

  // User resources
  getUserInfo: (id) => `/users/${id}`
};

export const actions = {
  reset({ commit }) {
    commit('reset')
  },
  async getBroadcast({ commit }, id) {
    commit('setBroadcastLoading')

    try {
      const { data } = await this.$axios.get(baseUrls.getBroadcast(id))

      commit('setBroadcastSuccess', data)
    } catch (err) {
      commit('setBroadcastFailure', err)
    }
  },
  async searchBroadcast({ commit }, { machine_id, status }) {
    commit('setBroadcastLoading')

    try {
      const params = { machine_id, status }
      const { data } = await this.$axios.get(baseUrls.searchBroadcast(), { params })

      const [first] = data.data
      commit('setBroadcastSuccess', first)
    } catch (err) {
      commit('setBroadcastFailure', err)
    }
  },
  async createBroadcast({ commit, state }) {
    const body = { title: state.campaign.name }

    const { data } = await this.$axios.post(baseUrls.createBroadcast(), body);

    commit('setBroadcastSuccess', data)
  },
  async updateBroadcast({ state }, params) {
    const {
      id,
      name: title,
      description: text,
      imageUrl: file_url,
      withImage,
      shortenLink: use_click_tracking,
      sendExitButton,
      contacts: {
        selected: phones, all
      },
      limit
    } = state.campaign;

    const send_at = limit.date && limit.time && new Date(`${limit.date}T${limit.time}`).toISOString()
    const sendToAll = all || !!params?.search
    const text_suffix = sendExitButton ? '\n\n_Para não receber mais mensagens, escreva *SAIR*_' : ''

    const body = {
      ...(title && { title }),
      ...(text && { text: turndown(`${text}${text_suffix}`) }),
      ...(file_url && withImage && { file_url }),
      ...(send_at && !limit.immediate && { send_at }),
      ...(!sendToAll && phones?.length && { phones: phones.flatMap(({phone}) => phone) }),
      use_click_tracking,
      status: +!!params
    }

    const config = {
      params: {
        ...(phones && { all: sendToAll, phone: params?.search })
      }
    }
    return this.$axios.put(baseUrls.updateBroadcast(id), body, config);
  },

  // Sync Actions
  async onSync({ commit }, event) {
    const syncMethods = { RECENT: 'setSyncProgressRecent', FULL: 'setSyncProgressFull' }

    const syncType = String(event.sync_type).toLocaleUpperCase()

    const action = syncMethods[syncType]

    if (action) commit(action, event.progress ?? 100)
  },
  async onSynced({ commit, rootState }) {
    commit('syncContactsLoading')

    try {
      await this.$axios.request({
        method: 'post',
        baseURL: rootState.zaps.coordinator_url,
        url: `/leads/import`,
        data: {
          machine_id: rootState.zaps.zap.id.toString()
        }
      })
      commit('syncContactsSuccess')
    } catch (err) {
      commit('syncContactsFailure', err)
    }
  },
  async getUserInfo(_, id) {
    if (!id) return null

    try {
      const { data } = await this.$axios.request({
        method: 'get',
        url: baseUrls.getUserInfo(id)
      })

      return data
    } catch (_) { }
  },
  async getSyncContactsCount({ commit, rootState }) {
    try {
      const { data } = await this.$axios.request({
        method: 'get',
        baseURL: rootState.zaps.coordinator_url,
        url: `/store/contacts/aggregate`,
        params: { session_id: rootState.zaps.session.id }
      })

      commit('setSyncContactsCount', data?.chat)
    } catch (_) { }
  },
  async getProfilePic({ state, rootState }) {
    const { data } = await this.$axios.request({
      method: 'post',
      baseURL: rootState.zaps.coordinator_url,
      url: `/drivers/contacts/profile-pic`,
      data: {
        session_id: rootState.zaps.session.id,
        contact_id: `${rootState.zaps.session.phone}@c.us`
      }
    })
    return data?.content
  },

  // FileUpload Actions
  async getSignedFileUploadUrl({ rootState }, { name, file, fileExtention }) {
    const filename = `machine_${rootState.zaps.zap.id}/${name}.${fileExtention}`

    const { data } = await this.$axios.get(baseUrls.getSignedFileUploadUrl(), { params: { filename } })

    return { ...data?.data, file, filename }
  },

  async sendAccessUploadFile({ }, { url, fields, file }) {
    const body = new FormData()
    Object.keys(fields || {}).forEach(key => body.append(key, fields[key]))
    body.append('file', file)

    const config = { 'Content-Type': 'multipart/form-data' }
    return axios.create().post(url, body, config)
  },
  buildStaticUrl(_, filename) {
    return `${process.env.STATIC_FILE_URL}/${encodeURIComponent(filename).replace(/ /g, '')}`
  },

  // Submit Actions
  async onSubmit({ commit, dispatch, rootState }, data) {
    const { current, steps } = rootState.stepper
    const actions = {
      [steps.BroadcastTitleForm]: 'onSubmitTitleForm',
      [steps.BroadcastDescriptionForm]: 'onSubmitDescriptionForm',
      [steps.BroadcastDateForm]: 'onSubmitDateForm',
      [steps.BroadcastContactsSyncPhonecode]: 'onSubmitContactsSyncPhonenumberForm',
      [steps.BroadcastContactsList]: 'onSubmitContactsForm',
      [steps.BroadcastSuccess]: 'onSubmitSuccessPage'
    }

    const action = actions[current]

    commit('setLoading', true)
    await dispatch(action, data)
    commit('setLoading', false)
  },

  async onSubmitOnboardingBroadcastForm({ commit, dispatch, state}) {
    commit('setCampaignLoading')

    const file = state.campaign.media.image
    const {
      name: title,
      description: text,
      shortenLink: use_click_tracking,
      sendExitButton,
      contacts: {
        selected: phones, all
      },
      limit
    } = state.campaign;

    if (file?.name) {
      try {
        const res = await dispatch('getSignedFileUploadUrl', file)
        const { status } = await dispatch('sendAccessUploadFile', res)

        if (status !== 204) throw new Error('Upload Failed')

        commit('setImageUrl', await dispatch('buildStaticUrl', res.filename))
      } catch (err) {
        commit('setCampaignFailure', err)
        return Swal.swalError({ text: 'Desculpe, houve um erro durante o upload da imagem' })
      }
    }

    const { imageUrl, withImage } = state.campaign
    if (withImage && !imageUrl) {
      commit('setCampaignFailure', new Error('É necessário adicionar uma imagem. Por favor, adicione uma imagem e tente novamente!'))
      return Swal.swalError({ text: 'É necessário adicionar uma imagem. Por favor, adicione uma imagem e tente novamente!' })
    }

    const send_at = limit.date && limit.time && new Date(`${limit.date}T${limit.time}`).toISOString()

    try {
      const body = {
      ...(title && { title }),
      ...(text && { text: turndown(text) }),
      ...(imageUrl && withImage && { file_url: imageUrl }),
      ...(send_at && !limit.immediate && { send_at }),
      ...(!all && phones?.length && { phones: phones.flatMap(({phone}) => phone) }),
      use_click_tracking,
    }

      const { data } = await this.$axios.post(baseUrls.createBroadcast(), body);

      commit('setCampaignSuccess', data)
    } catch (err) {
      commit('setCampaignFailure', err)
      return Swal.swalError({ text: 'Desculpe, houve um erro durante a criação da sua campanha. Por favor, tente novamente!' })
    }

    dispatch('stepper/nextStep', null, { root: true })
  },

  async onSubmitOnboardingBroadcast({ commit, dispatch, state }) {
    try {
      commit('setCampaignLoading')

      const { data } = await dispatch('updateBroadcast', { ...state.campaign.contacts.params })

      // $mixpanel.track('[onboarding] Send Broadcast')

      commit('setCampaignSuccess', data)
      dispatch('stepper/nextStep', null, { root: true })
    } catch (err) {
      commit('setCampaignFailure', err)
      Swal.swalError({ text: 'Desculpe, houve um erro durante a criação da sua campanha. Por favor, tente novamente!' })
    }
  },

  async onSubmitTitleForm({ dispatch, state }, { params, $mixpanel }) {
    const actions = {
      create: 'createBroadcast',
      update: 'updateBroadcast'
    }

    const action =
      !!state.campaign.id ? actions.update : actions.create

    try {
      await dispatch(action)

      const { source } = params || {}
      if (source === 'onboarding') $mixpanel.track('[onboarding] Complete Title Step')

      dispatch('stepper/nextStep', null, { root: true })
    } catch (err) {
      Swal.swalError({
        text: `Erro ao ${action === action.update ? 'atualizar' : 'criar'} campanha. Por favor, corrija e tente novamente!`
      })
    }
  },

  async onSubmitDescriptionForm({ commit, dispatch, state }, { params, $mixpanel }) {
    const file = state.campaign.media.image

    if (file?.name) {
      try {
        const res = await dispatch('getSignedFileUploadUrl', file)
        const { status } = await dispatch('sendAccessUploadFile', res)

        if (status !== 204) throw new Error('Upload Failed')

        commit('setImageUrl', await dispatch('buildStaticUrl', res.filename))
      } catch (err) {
        return Swal.swalError({ text: 'Desculpe, houve um erro durante o upload da imagem' })
      }
    }

    const { imageUrl, withImage } = state.campaign
    if (withImage && !imageUrl) {
      return Swal.swalError({ text: 'É necessário adicionar uma imagem. Por favor, adicione uma imagem e tente novamente!' })
    }

    try {
      await dispatch('updateBroadcast')

      const { source } = params || {}
      if (source === 'onboarding') {
        $mixpanel.track('[onboarding] Complete Campaign Step', {
          type: withImage ? 'text_with_image' : 'text'
        })
      }

      dispatch('stepper/nextStep', null, { root: true })
    } catch (err) {
      Swal.swalError({ text: 'Desculpe, houve um erro durante a atualização da sua campanha. Por favor, tente novamente!' })
    }

  },

  async onSubmitDateForm({ dispatch, state }, { params, $mixpanel }) {
    try {
      await dispatch('updateBroadcast')

      const { source } = params || {}
      if (source === 'onboarding') {
        const { immediate } = state.campaign.limit
        $mixpanel.track('[onboarding] Complete Date/Hour Step', { type: immediate ? 'immediate' : 'scheduled' })
      }

      dispatch('stepper/nextStep', null, { root: true })
    } catch (err) {
      Swal.swalError({ text: 'Desculpe, houve um erro durante a atualização da sua campanha. Por favor, tente novamente!' })
    }
  },

  async onSubmitContactsSyncPhonenumberForm({ commit }, { $refs, params, $mixpanel }) {
    try {
      const phonecode = $refs.broadcastcontactscontainer.$refs.broadcastcontactssync.$refs.phonecode
      const valid = await phonecode.isValid()

      if (valid) {
        const { source } = params || {}
        if (source === 'onboarding') $mixpanel.track('[onboarding] Request Pairing Code')

        await phonecode.loadPhoneCodeSource()
        commit('setSyncPhoneLoaded', true)
      }
    } catch (err) {
      Swal.swalError({ text: 'Desculpe, houve um erro durante a atualização da sua campanha. Por favor, tente novamente!' })
    }
  },

  async onSubmitContactsForm({ dispatch }, { params, $mixpanel }) {
    try {
      await dispatch('updateBroadcast', params)

      const { source } = params || {}
      if (source === 'onboarding') $mixpanel.track('[onboarding] Send Broadcast')

      dispatch('stepper/nextStep', null, { root: true })
    } catch (err) {
      Swal.swalError({ text: 'Desculpe, houve um erro durante o envio das informações. Por favor, tente novamente!' })
    }
  },

  async onSubmitSuccessPage({ dispatch }) {
    await dispatch('reset')
    await dispatch('stepper/reset', {}, { root: true })

    await this.$auth.fetchUser()
    this.$router.push({
      name: 'index',
      params: { popup: false }
    })
  }
}
