import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _3f17b24d = () => interopDefault(import('../pages/atendente/index.vue' /* webpackChunkName: "pages/atendente/index" */))
const _e1b48c82 = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _43dade0c = () => interopDefault(import('../pages/help/index.vue' /* webpackChunkName: "pages/help/index" */))
const _dce35594 = () => interopDefault(import('../pages/lite.vue' /* webpackChunkName: "pages/lite" */))
const _28fb8ddc = () => interopDefault(import('../pages/lite/messaging/new/_phone.vue' /* webpackChunkName: "pages/lite/messaging/new/_phone" */))
const _76ae8af6 = () => interopDefault(import('../pages/lite/messaging/_uuid.vue' /* webpackChunkName: "pages/lite/messaging/_uuid" */))
const _2fb7dfcc = () => interopDefault(import('../pages/livechat.vue' /* webpackChunkName: "pages/livechat" */))
const _ae5085d2 = () => interopDefault(import('../pages/livechat/_uuid.vue' /* webpackChunkName: "pages/livechat/_uuid" */))
const _9230ff58 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _43e3e4ac = () => interopDefault(import('../pages/messaging.vue' /* webpackChunkName: "pages/messaging" */))
const _e01b3a9e = () => interopDefault(import('../pages/messaging/new/_phone.vue' /* webpackChunkName: "pages/messaging/new/_phone" */))
const _6336cc12 = () => interopDefault(import('../pages/messaging/_uuid.vue' /* webpackChunkName: "pages/messaging/_uuid" */))
const _c592858a = () => interopDefault(import('../pages/old_onboarding.vue' /* webpackChunkName: "pages/old_onboarding" */))
const _a0ecef34 = () => interopDefault(import('../pages/onboarding/index.vue' /* webpackChunkName: "pages/onboarding/index" */))
const _1ada429e = () => interopDefault(import('../pages/payment/index.vue' /* webpackChunkName: "pages/payment/index" */))
const _ad525630 = () => interopDefault(import('../pages/subscription/index.vue' /* webpackChunkName: "pages/subscription/index" */))
const _835ecbae = () => interopDefault(import('../pages/auth/forgot-password/index.vue' /* webpackChunkName: "pages/auth/forgot-password/index" */))
const _4dc3aad4 = () => interopDefault(import('../pages/auth/signin/index.vue' /* webpackChunkName: "pages/auth/signin/index" */))
const _583e5e6c = () => interopDefault(import('../pages/auth/signup/index.vue' /* webpackChunkName: "pages/auth/signup/index" */))
const _795bce06 = () => interopDefault(import('../pages/broadcast/new/index.vue' /* webpackChunkName: "pages/broadcast/new/index" */))
const _4a0c20b9 = () => interopDefault(import('../pages/login/forgot_password.vue' /* webpackChunkName: "pages/login/forgot_password" */))
const _c4931b06 = () => interopDefault(import('../pages/login/page.vue' /* webpackChunkName: "pages/login/page" */))
const _851e7c00 = () => interopDefault(import('../pages/register/free.vue' /* webpackChunkName: "pages/register/free" */))
const _0a3d428d = () => interopDefault(import('../pages/subscription/change/index.vue' /* webpackChunkName: "pages/subscription/change/index" */))
const _b3f8ef40 = () => interopDefault(import('../pages/zap/attention.vue' /* webpackChunkName: "pages/zap/attention" */))
const _97777b2c = () => interopDefault(import('../pages/zap/benefits.vue' /* webpackChunkName: "pages/zap/benefits" */))
const _4b53ff01 = () => interopDefault(import('../pages/zap/first_access.vue' /* webpackChunkName: "pages/zap/first_access" */))
const _f635a734 = () => interopDefault(import('../pages/zap/ImportContacts.vue' /* webpackChunkName: "pages/zap/ImportContacts" */))
const _3d47548f = () => interopDefault(import('../pages/zap/qrcode_v2.vue' /* webpackChunkName: "pages/zap/qrcode_v2" */))
const _abd2b60a = () => interopDefault(import('../pages/zap/templates.vue' /* webpackChunkName: "pages/zap/templates" */))
const _6da10a46 = () => interopDefault(import('../pages/register/auth/_token.vue' /* webpackChunkName: "pages/register/auth/_token" */))
const _277afa1e = () => interopDefault(import('../pages/zap/simulator/_id.vue' /* webpackChunkName: "pages/zap/simulator/_id" */))
const _0c867e7a = () => interopDefault(import('../pages/qrcode/_token.vue' /* webpackChunkName: "pages/qrcode/_token" */))
const _7324065c = () => interopDefault(import('../pages/recovery/_token.vue' /* webpackChunkName: "pages/recovery/_token" */))
const _484a028e = () => interopDefault(import('../pages/register/_token.vue' /* webpackChunkName: "pages/register/_token" */))
const _bf39710c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _a3d26b06 = () => interopDefault(import('../pages/index/index.vue' /* webpackChunkName: "pages/index/index" */))
const _cd748d28 = () => interopDefault(import('../pages/index/broadcast.vue' /* webpackChunkName: "pages/index/broadcast" */))
const _28cb8954 = () => interopDefault(import('../pages/index/configuracao.vue' /* webpackChunkName: "pages/index/configuracao" */))
const _234b83c2 = () => interopDefault(import('../pages/index/leads.vue' /* webpackChunkName: "pages/index/leads" */))
const _3691b3ac = () => interopDefault(import('../pages/index/myaccount.vue' /* webpackChunkName: "pages/index/myaccount" */))
const _a3c8c732 = () => interopDefault(import('../pages/index/sequencia.vue' /* webpackChunkName: "pages/index/sequencia" */))
const _5eb7a986 = () => interopDefault(import('../pages/index/chat/messaging/_uuid.vue' /* webpackChunkName: "pages/index/chat/messaging/_uuid" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/atendente",
    component: _3f17b24d,
    name: "atendente"
  }, {
    path: "/dashboard",
    component: _e1b48c82,
    name: "dashboard"
  }, {
    path: "/help",
    component: _43dade0c,
    name: "help"
  }, {
    path: "/lite",
    component: _dce35594,
    name: "lite",
    children: [{
      path: "messaging/new/:phone?",
      component: _28fb8ddc,
      name: "lite-messaging-new-phone"
    }, {
      path: "messaging/:uuid?",
      component: _76ae8af6,
      name: "lite-messaging-uuid"
    }]
  }, {
    path: "/livechat",
    component: _2fb7dfcc,
    name: "livechat",
    children: [{
      path: ":uuid?",
      component: _ae5085d2,
      name: "livechat-uuid"
    }]
  }, {
    path: "/login",
    component: _9230ff58,
    name: "login"
  }, {
    path: "/messaging",
    component: _43e3e4ac,
    name: "messaging",
    children: [{
      path: "new/:phone?",
      component: _e01b3a9e,
      name: "messaging-new-phone"
    }, {
      path: ":uuid?",
      component: _6336cc12,
      name: "messaging-uuid"
    }]
  }, {
    path: "/old_onboarding",
    component: _c592858a,
    name: "old_onboarding"
  }, {
    path: "/onboarding",
    component: _a0ecef34,
    name: "onboarding"
  }, {
    path: "/payment",
    component: _1ada429e,
    name: "payment"
  }, {
    path: "/subscription",
    component: _ad525630,
    name: "subscription"
  }, {
    path: "/auth/forgot-password",
    component: _835ecbae,
    name: "auth-forgot-password"
  }, {
    path: "/auth/signin",
    component: _4dc3aad4,
    name: "auth-signin"
  }, {
    path: "/auth/signup",
    component: _583e5e6c,
    name: "auth-signup"
  }, {
    path: "/broadcast/new",
    component: _795bce06,
    name: "broadcast-new"
  }, {
    path: "/login/forgot_password",
    component: _4a0c20b9,
    name: "login-forgot_password"
  }, {
    path: "/login/page",
    component: _c4931b06,
    name: "login-page"
  }, {
    path: "/register/free",
    component: _851e7c00,
    name: "register-free"
  }, {
    path: "/subscription/change",
    component: _0a3d428d,
    name: "subscription-change"
  }, {
    path: "/zap/attention",
    component: _b3f8ef40,
    name: "zap-attention"
  }, {
    path: "/zap/benefits",
    component: _97777b2c,
    name: "zap-benefits"
  }, {
    path: "/zap/first_access",
    component: _4b53ff01,
    name: "zap-first_access"
  }, {
    path: "/zap/ImportContacts",
    component: _f635a734,
    name: "zap-ImportContacts"
  }, {
    path: "/zap/qrcode_v2",
    component: _3d47548f,
    name: "zap-qrcode_v2"
  }, {
    path: "/zap/templates",
    component: _abd2b60a,
    name: "zap-templates"
  }, {
    path: "/register/auth/:token?",
    component: _6da10a46,
    name: "register-auth-token"
  }, {
    path: "/zap/simulator/:id?",
    component: _277afa1e,
    name: "zap-simulator-id"
  }, {
    path: "/qrcode/:token?",
    component: _0c867e7a,
    name: "qrcode-token"
  }, {
    path: "/recovery/:token?",
    component: _7324065c,
    name: "recovery-token"
  }, {
    path: "/register/:token?",
    component: _484a028e,
    name: "register-token"
  }, {
    path: "/",
    component: _bf39710c,
    children: [{
      path: "",
      component: _a3d26b06,
      name: "index"
    }, {
      path: "broadcast",
      component: _cd748d28,
      name: "index-broadcast"
    }, {
      path: "configuracao",
      component: _28cb8954,
      name: "index-configuracao"
    }, {
      path: "leads",
      component: _234b83c2,
      name: "index-leads"
    }, {
      path: "myaccount",
      component: _3691b3ac,
      name: "index-myaccount"
    }, {
      path: "sequencia",
      component: _a3c8c732,
      name: "index-sequencia"
    }, {
      path: "chat/messaging/:uuid?",
      component: _5eb7a986,
      name: "index-chat-messaging-uuid"
    }]
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
