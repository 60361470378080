<template>
  <div class="footer">
    <div class="footer-content">
      <div class="footer-content-copyright">
        <a href="https://clickzap.com.br/" target="_blank">
          <span>© Clickzap
            {{ currentYear }}
          </span>
        </a>
        <a href="https://czap.me/210YJLz2PPf3WZCqc">
          <span>Fale conosco</span>
        </a>
      </div>
      <div class="footer-content-terms">
        <a href="https://clickzap.com.br/termos-de-uso/" target="_blank">
          <span>
            Termos de uso
          </span>
        </a>

        <a href="https://clickzap.com.br/termos-de-uso/" target="_blank">
          <span>Política de privacidade</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  props: {},
  computed: {
    currentYear() {
      return new Date().getFullYear() || ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'assets/scss/variables';

.footer {
  position: relative;
  width: 100vw;

  border-top-width: 1px;
  border-top-color: $neutral200;
  border-top-style: solid;

  & a {
    @include ParagraphSmall;
    font-family: $monserratFontFamily;
    color: $neutral400;
    text-decoration: none;
  }

  &-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 auto;

    padding: $scale800;
    height: $scale1600;
    max-width: 1080px;

    &-copyright {
      display: flex;
      gap: $scale800;
    }

    &-terms {
      display: flex;
      gap: $scale800;
    }
  }
}
</style>
