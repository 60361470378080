<template>
  <div
    v-show="show"
    :class="{
      'test-badge': true,
      rounded: true,
      [status]: status,
    }"
    @click="goToPlans"
  >
    <i v-if="remaining <= 1" class="fa fa-circle-exclamation" />
    <i v-if="remaining >= 2" class="far fa-clock" />
    {{ label }}
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { redirectUserToPayment } from '@/helpers/redirects'

export default {
  name: 'TestBadge',
  computed: {
    ...mapGetters([
      'loggedUserExpirated',
      'loggedUserInactive',
      'loggedInUser'
    ]),
    show() {
      return this.label !== undefined
    },
    label() {
      if (this.loggedUserInactive)
        return 'Sua conta está inativa. Clique aqui para regularizar.'

      if (!this.loggedInUser.is_free) {
        if (this.remaining < 0)
          return 'Sua conta está em atraso. Clique aqui para regularizar.'
        else return undefined
      } else {
        if (this.remaining < 0)
          return `Seu período de teste acabou. Clique aqui para assinar.`
        if (this.remaining === 0)
          return `${this.remainingHours} horas de teste restante.`
        if (this.remaining === 1)
          return `${this.remaining} dia de teste restante`

        return `${this.remaining} dias de teste restantes`
      }
    },
    status() {
      if (this.loggedUserInactive) return 'error'
      else if (!this.loggedInUser.is_free && this.remaining < 0)
        return 'warning'

      if (this.remaining <= 1) return 'error'
      if (this.remaining <= 3) return 'warning'
      return 'default'
    },
    remaining() {
      const oneDay = 24 * 60 * 60 * 1000
      const date = new Date(this.loggedInUser.expiration_date)
      const now = new Date()
      return Math.round((date - now) / oneDay)
    },
    remainingHours() {
      const oneHour = 60 * 60 * 1000
      const date = new Date(this.loggedInUser.expiration_date)
      const now = new Date()
      return Math.round((date - now) / oneHour)
    }
  },
  methods: {
    goToPlans() {
      redirectUserToPayment(this.loggedInUser, this.$mixpanel)
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'assets/scss/variables';

.test-badge {
  @include LabelSmall;
  font-weight: 700;
  color: $neutral500;
  cursor: pointer;

  display: flex;
  background-color: $neutral200;
  padding: 8px 12px;
  gap: 10px;

  &.rounded {
    border-radius: 40px;
  }

  &.warning {
    background-color: $yellow200;
  }

  &.error {
    background-color: $negative200;
  }
}
</style>
