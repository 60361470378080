<template>
  <div :data-app="true">
    <cz-header />
    <div>
      <nuxt />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import { redirectUserToPayment } from '@/helpers/redirects'
import ChatMixin from '@/mixins/chat'
import Mixpanel from '@/mixins/mixpanel'
import Sentry from '@/mixins/sentry'

import CzHeader from '@/components/base/header'

export default {
  components: {
    CzHeader
  },
  mixins: [ChatMixin, Mixpanel, Sentry],
  data() {
    return {
      menu: false,
      countDown: null
    }
  },
  computed: {
    ...mapGetters([
      'isAuthenticated',
      'loggedInUser',
      'loggedUser',
      'loggedUserInactive',
      'loggedUserExpirated'
    ]),
    isAttendant() {
      return window.localStorage.getItem('auth.strategy') == 'chat'
    },
    loggedUsername() {
      return this.loggedInUser ? this.loggedInUser.name : '-'
    },
    zap() {
      return this.$store.state.zaps.zap || {}
    }
  },
  watch: {
    $route(to, from) {
      if (
        window.localStorage.getItem('auth.strategy') == 'chat' &&
        to.name != 'messaging-uuid' &&
        to.name != 'lite-messaging-uuid' &&
        to.name != 'livechat-uuid' &&
        to.name != 'livechat'
      ) {
        this.$router.push({ name: 'livechat' })
      } else {
        document.title = 'Clickzap'
      }
      if (this.loggedUserExpirated || this.loggedUserInactive) {
        this.goToPlans()
        // this.$refs.expired.open()
      }
    }
  },
  notifications: {
    showPusherMessage: {
      title: '',
      message: 'Failed to authenticate',
      type: 'success'
    }
  },
  beforeCreate() {
    if (
      window.localStorage.getItem('auth.strategy') == 'chat' &&
      this.$route.name != 'messaging-new-phone' &&
      this.$route.name != 'lite-messaging-uuid' &&
      this.$route.name != 'livechat-uuid' &&
      this.$route.name != 'livechat'
    )
      this.$router.push({ name: 'livechat' })
  },
  beforeMount() {
    if (
      window.localStorage.getItem('auth.strategy') == 'chat' &&
      this.$route.name != 'messaging-new-phone' &&
      this.$route.name != 'lite-messaging-uuid' &&
      this.$route.name != 'livechat-uuid' &&
      this.$route.name != 'livechat'
    )
      this.$router.push({ name: 'livechat' })
  },
  created() {
    var that = this
    this.$on('hideOnLoad')
    /* if (this.zap !== undefined) {
      if (window.PUSHER_CHANNEL === undefined) {
        window.PUSHER_CHANNEL = this.$pusher.subscribe('machine_events')
        var that = this
        window.PUSHER_CHANNEL.bind(`machine_${this.zap.id}`, function(data) {
          that.showPusherMessage({
            message: data.text,
            image: data.image
          })
        })
      }
    } */
  },
  mounted() {
    this.initcountDown()
    this.hideZohoSupport()
  },
  methods: {
    ...mapActions({
      getZap: 'zaps/get'
    }),
    goToPlans() {
      redirectUserToPayment(this.loggedInUser, this.$mixpanel)
    },
    initcountDown() {
      var countDownDate = new Date(this.loggedUser.expiration_date).getTime()
      var self = this
      var x = setInterval(function() {
        var now = new Date().getTime()
        var distance = countDownDate - now
        var days = Math.floor(distance / (1000 * 60 * 60 * 24))
        var hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        )
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
        var seconds = Math.floor((distance % (1000 * 60)) / 1000)
        hours = hours < 10 ? '0' + hours : hours
        minutes = minutes < 10 ? '0' + minutes : minutes
        seconds = seconds < 10 ? '0' + seconds : seconds
        self.countDown =
          days + ' dias ' + hours + 'h ' + minutes + 'min ' + seconds + 's'
        if (distance < 0) {
          self.countDown = 'EXPIROU'
          clearInterval(x)
        }
      }, 1000)
    },
    toggleMenu() {
      this.menu = this.menu ? false : true
    },
    handleCloseMenu() {
      this.menu = false
    },
    async logout() {
      await this.$auth.logout()
      let route_logout =
        window.localStorage.getItem('auth.strategy') == 'chat'
          ? '/login/chat'
          : '/auth/signin'
      window.location = route_logout
      localStorage.clear()
    },
    hideZohoSupport() {
      const zohoDesk = document.getElementById('zsiq_float')
      if (zohoDesk) {
        zohoDesk.style.display = 'none'
      }
    }
  }
}
</script>

<style scoped>
.page-footer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  padding: 20px;
  font-size: 14px;
  color: #6c757d;
}
.expiration {
  padding-top: 20px;
  color: red;
  text-align: center;
  font-size: 16px;
}
</style>
